module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"tr","locales":"tr en","configPath":"/Users/onur/websites/ilyun-backup/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./i18n/react-intl/en.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/onur/websites/ilyun-backup/src/components/layout.js"},"gatsbyRemarkPlugins":["gatsby-remark-embedder","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"withWebp":true,"showCaptions":true,"tracedSVG":false,"wrapperStyle":"margin: 7vw 0;"}},{"resolve":"gatsby-plugin-webpack-bundle-analyser-v2","options":{"devMode":true}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-smartypants"],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-P76RCL40C4","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pianist Ilyun Bürkev","short_name":"Ilyun Bürkev","start_url":"/","background_color":"#ffffff","lang":"tr","theme_color":"#663399","display":"minimal-ui","icon":"/Users/onur/websites/ilyun-backup/content/assets/ilyun-burkev-icon.png","localize":[{"start_url":"/en/","lang":"en","name":"Pianist İlyun Bürkev","short_name":"Ilyun Bürkev","description":"Official Website of Pianist İlyun Bürkev"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd6faa087fa720831953e86545487aba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"yellow","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ilyun-burkev.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
